// React libs
import React, { FC, useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
// Layout
import AppLayout from '../../../App/Components/Layout/AppLayout/AppLayout';
// Components
import MapHeader from '../Components/Layout/Header/MapHeader';
import MapSidebarProject from '../Components/Layout/Sidebar/MapSidebarProject/MapSidebarProject';
import MapSidebar from '../Components/Layout/Sidebar/MapSidebar';
import MapCanvas from '../Components/Canvas/MapCanvas';
import ExternalTerritoriesMenu from '../Components/ExternalTerritoriesMenu/ExternalTerritoriesMenu';
// Contexts
import ActiveLayerContext from '../Data/Contexts/ActiveLayerContext';
import PhaseTypeContext from '../Data/Contexts/PhaseTypeContext';
import LinkTypeContext from '../Data/Contexts/LinkTypesContext';
import PoisContext from '../Data/Contexts/PoisContext';
import PoiTypeContext from '../Data/Contexts/PoiTypesContext';
import PoisLinksContext from '../Data/Contexts/PoisLinksContext';
import MapConfigContext from '../Data/Contexts/MapConfigContext';
import MapContext from '../Data/Contexts/MapContext';
import MapLegendContext from '../Data/Contexts/MapLegendContext';
import MapFiltersContext from '../Data/Contexts/MapFiltersContext';
import PoiFormPanelContext, { poiPanelType } from '../Data/Contexts/PoiFormPanelContext';
import MapSidebarProjectContext, { ISidebarProjectData, sidebarDefaultProjectData } from '../Data/Contexts/MapSidebarProjectContext';
// Services
import LocalStorage from '../../../Core/Data/Services/Storage/LocalStorage';
import MapService from '../Data/Services/MapService';
// Type
import * as Types from './MapScene.type';
import * as MapTypes from '../Data/Models/Map.type';
import * as CoreTypes from '../../../Core/Data/Models/Core.type';
// Common
import CoreCommon from '../../../Core/Resources/Common';
// Utils
import { formatMapFilters } from '../Utils/Map'

const MapScene: FC<Types.IProps> = ({ location }) => {
  // Variables
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common', 'map']);
  const defaultMapFilters = useMemo(() => {
    const persistedMapFilters = LocalStorage.get(LocalStorage.keys.mapFilters)
    return persistedMapFilters != null ? {
      ...persistedMapFilters,
      needMapRefresh: true,
    } : {
      initialized: false,
      links: false,
      markers: { links: {}, projects: {}, resources: {} },
      needMapRefresh: true,
      advanced: {}
    }
  }, [])

  // Refs
  const mapRef = useRef();

  // State
  const [pois, updatePois]: [
    MapTypes.IPoi[],
    (pois: MapTypes.IPoi[]) => void
  ] = useState<MapTypes.IPoi[]>([]);
  const [arePoisLoading, setPoisLoading]: [boolean, Function] = useState<
    boolean
  >(false);
  const [map, setMap] = useState<any>()
  const [poisLinks, updatePoisLinks]: [
    MapTypes.IPoiLink[],
    (pois: MapTypes.IPoiLink[]) => void
  ] = useState<MapTypes.IPoiLink[]>([]);
  const [mapConfig, updateMapConfig]: [
    MapTypes.IMapConfig | undefined,
    (config: MapTypes.IMapConfig | undefined) => void
  ] = useState<MapTypes.IMapConfig | undefined>(undefined);
  const [isMapConfigLoading, setMapConfigLoading]: [
    boolean,
    Function
  ] = useState<boolean>(false);
  const [phaseTypes, updatePhaseTypes]: [
    MapTypes.IPhaseType[],
    (types: MapTypes.IPhaseType[]) => void
  ] = useState<MapTypes.IPhaseType[]>([]);
  const [arePhaseTypesLoading, setPhaseTypesLoading]: [
    boolean,
    Function
  ] = useState<boolean>(false);
  const [poiTypes, updatePoiTypes]: [
    MapTypes.IPoiType[],
    (pois: MapTypes.IPoiType[]) => void
  ] = useState<MapTypes.IPoiType[]>([]);
  const [arePoiTypesLoading, setPoiTypesLoading]: [
    boolean,
    Function
  ] = useState<boolean>(false);
  const [linkTypes, updateLinkTypes]: [
    MapTypes.ILinkType[],
    (links: MapTypes.ILinkType[]) => void
  ] = useState<MapTypes.ILinkType[]>([]);
  const [areLinkTypesLoading, setLinkTypesLoading]: [
    boolean,
    Function
  ] = useState<boolean>(false);
  const [mapFilters, updateMapFiltersState]: [
    MapTypes.IMapFilters,
    (filters: MapTypes.IMapFilters) => void
  ] = useState<MapTypes.IMapFilters>(defaultMapFilters);
  const [hiddenLegendMapId, updateHiddenLegendMapId]: [
    string | undefined,
    (id: string | undefined) => void
  ] = useState<string | undefined>();
  const [needRefreshLegendPosition, setNeedRefreshLegendPosition] = useState<boolean>(false)
  const [areAdvancedFiltersSelected, updateAreAdvancedFiltersSelected] = useState<boolean>(false)
  const [areFavoriteFiltersSelected, updateAreFavoriteFiltersSelected] = useState<boolean>(false)
  const [isSidebarOpened, setSideBarOpened]: [boolean, Function] = useState(
    false
  );
  const [sidebarContentKey, setSidebarContentKey]: [
    string,
    Function
  ] = useState('');
  const [title, setTitle]: [string, Function] = useState('');
  const [currentLayer, setCurrentLayer]: [
    MapTypes.ILayer | undefined,
    Function
  ] = useState(LocalStorage.get(LocalStorage.keys.mapLayer) ?? undefined);
  const [otherTerritory, setOtherTerritory]: [
    MapTypes.IExternalTerritory | undefined,
    Function
  ] = useState(undefined);
  const [currentCenter, setCurrentCenter]: [
    [number, number] | undefined,
    Function
  ] = useState<[number, number] | undefined>(undefined);
  const [currentZoom, setCurrentZoom]: [
    number | undefined,
    Function
  ] = useState<number | undefined>(undefined);
  const [activePoiPanelType, setActivePoiPanelType] = useState<poiPanelType>(undefined)
  const [poiToEdit, setPoiToEdit] = useState<MapTypes.IPoi | undefined>(undefined)
  const [sidebarProjectData, setSidebarProjectData] = useState<ISidebarProjectData>(sidebarDefaultProjectData)

  // handlers
  const updateMapFilters = useCallback((mapFilters: MapTypes.IMapFilters) => {
    updateMapFiltersState(mapFilters)
    !mapFilters.temporal && LocalStorage.set(LocalStorage.keys.mapFilters, mapFilters)
  }, [])

  // Effects
  // center on coordinates if passed in the query or stored in the browser
  useEffect(() => {
    const query = new URLSearchParams(location?.search)
    const coordinates = query.get('coordinates')
    if (coordinates != null) {
      setCurrentCenter(JSON.parse(coordinates))
      setCurrentZoom(100)
      return
    }

    const data = LocalStorage.get(LocalStorage.keys.mapCoordinates)
    if (data != null) {
      setCurrentCenter(data.center)
      setCurrentZoom(data.zoom)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // store current zoom/center on unmount
  useEffect(() => {
    return () => {
      if (map != null) {
        const center = map.getCenter()
        const zoom = map.getZoom()
        LocalStorage.set(LocalStorage.keys.mapCoordinates, {
          center: center && [center.lat, center.lng],
          zoom,
        })
      }
    }
  }, [map])

  // store current layer on unmount
  useEffect(() => {
    return () => {
      LocalStorage.set(LocalStorage.keys.mapLayer, currentLayer)
    }
  }, [currentLayer])

  useEffect(() => {
    if (currentLayer) {
      setTitle(currentLayer!.name);
    } else if (mapConfig && mapConfig.layerMap) {
      setTitle(mapConfig.layerMap.name);
    }
  }, [currentLayer, mapConfig, setTitle]);
  useEffect(() => {
    if (!currentLayer) {
      setCurrentLayer(mapConfig?.layerMap);
    }
  }, [currentLayer, mapConfig]);
  useEffect(() => {
    if (mapFilters.needMapRefresh && !arePoisLoading) {
      setPoisLoading(true);
      const filters = formatMapFilters(mapFilters);
      MapService.getPois(filters)
        .then((data: MapTypes.IPoiData) => {
          updatePois(data.data.markers);
          updatePoisLinks(data.data.links);
          const newMapFilters = { ...mapFilters };
          newMapFilters.needMapRefresh = false;
          updateMapFilters(newMapFilters);
          setPoisLoading(false);
        })
        .catch((e: CoreTypes.IWsException) => {
          enqueueSnackbar(
            e?.error?.message || t('common:errors.defaultMessage'),
            {
              ...CoreCommon.Constantes.snackbarDefaultProps,
              variant: 'error',
            }
          );
          setPoisLoading(false);
        });
    } else if (pois.length === 0) {
      setActivePoiPanelType(undefined);
      setPoiToEdit(undefined)
    }
  }, [
    arePoisLoading,
    enqueueSnackbar,
    mapFilters,
    pois,
    setPoisLoading,
    t,
  ]);
  useEffect(() => {
    if (!mapConfig && !isMapConfigLoading) {
      setMapConfigLoading(true);
      MapService.getMapConfig()
        .then((data: MapTypes.IMapConfigData) => {
          updateMapConfig(data.data);
          setMapConfigLoading(false);
        })
        .catch((e: CoreTypes.IWsException) => {
          enqueueSnackbar(
            e?.error?.message || t('common:errors.defaultMessage'),
            {
              ...CoreCommon.Constantes.snackbarDefaultProps,
              variant: 'error',
            }
          );
          setMapConfigLoading(false);
        });
    }
  }, [enqueueSnackbar, isMapConfigLoading, mapConfig, setMapConfigLoading, t]);
  useEffect(() => {
    setPhaseTypesLoading(true);
    MapService.getPhaseTypes()
      .then((data: MapTypes.IPhaseTypeData) => {
        updatePhaseTypes(data.data);
        setPhaseTypesLoading(false);
      })
      .catch((e: CoreTypes.IWsException) => {
        enqueueSnackbar(
          e?.error?.message || t('common:errors.defaultMessage'),
          {
            ...CoreCommon.Constantes.snackbarDefaultProps,
            variant: 'error',
          }
        );
        setPhaseTypesLoading(false);
      });
  }, [
    enqueueSnackbar,
    t,
  ]);
  useEffect(() => {
    setPoiTypesLoading(true);
    MapService.getPoiTypes()
      .then((data: MapTypes.IPoiTypesData) => {
        updatePoiTypes(data.data);
        setPoiTypesLoading(false);
      })
      .catch((e: CoreTypes.IWsException) => {
        enqueueSnackbar(
          e?.error?.message || t('common:errors.defaultMessage'),
          {
            ...CoreCommon.Constantes.snackbarDefaultProps,
            variant: 'error',
          }
        );
        setPoiTypesLoading(false);
      });
  }, [enqueueSnackbar, t]);
  useEffect(() => {
    setLinkTypesLoading(true);
    MapService.getLinkTypes()
      .then((data: MapTypes.ILinkTypesData) => {
        updateLinkTypes(data.data);
        setLinkTypesLoading(false);
      })
      .catch((e: CoreTypes.IWsException) => {
        enqueueSnackbar(
          e?.error?.message || t('common:errors.defaultMessage'),
          {
            ...CoreCommon.Constantes.snackbarDefaultProps,
            variant: 'error',
          }
        );
        setLinkTypesLoading(false);
      });
  }, [enqueueSnackbar, t]);

  // Getters
  const isLoading = () => {
    return (
      process.env.NODE_ENV !== 'test' &&
      (isMapConfigLoading || arePoisLoading || arePhaseTypesLoading || arePoiTypesLoading || areLinkTypesLoading)
    );
  };
  const getLoadingMessages = () => {
    const messages = [];
    if (arePhaseTypesLoading) {
      messages.push(t('common:loading.phaseTypes'));
    }
    if (isMapConfigLoading) {
      messages.push(t('map:loading.config'));
    }
    if (arePoisLoading) {
      messages.push(t('map:loading.pois'));
    }
    if (arePoiTypesLoading) {
      messages.push(t('map:loading.poiTypes'));
    }
    if (areLinkTypesLoading) {
      messages.push(t('map:loading.linkTypes'));
    }
    return messages;
  };

  // Handlers
  const toggleMapSideBar = (isOpened: boolean, content: string) => {
    setSideBarOpened(isOpened);
    setSidebarContentKey(isOpened ? content : '');
  };
  const onLayerClick = (layer: MapTypes.ILayer) => {
    setCurrentLayer(layer);
  };
  const onTerritoryClick = (t: MapTypes.IExternalTerritory) => {
    setCurrentZoom(t.zoom);
    setCurrentCenter([t.coords.lat, t.coords.lng]);
    setOtherTerritory(t);
  };
  const resetOtherTerritory = () => {
    setCurrentZoom(undefined);
    setCurrentCenter(undefined);
    setOtherTerritory(undefined);
  };
  const onExport = (output: 'pdf' | 'png') => {
    (mapRef?.current as any)?.export(output);
  };

  return (
    <MapSidebarProjectContext.Provider value={{ data: sidebarProjectData, setData: setSidebarProjectData }}>
      <PoiFormPanelContext.Provider value={{ activePoiPanelType, setActivePoiPanelType, poiToEdit, setPoiToEdit }}>
        <MapContext.Provider value={{ map, setMap, setCurrentZoom, setCurrentCenter }}>
          <PoiTypeContext.Provider value={{ poiTypes, updatePoiTypes }}>
            <PhaseTypeContext.Provider value={{ phaseTypes, updatePhaseTypes }}>
              <LinkTypeContext.Provider value={{ linkTypes, updateLinkTypes }}>
                <PoisContext.Provider value={{ pois, updatePois }}>
                  <PoisLinksContext.Provider value={{ poisLinks, updatePoisLinks }}>
                    <MapConfigContext.Provider value={{ mapConfig, updateMapConfig }}>
                      <MapFiltersContext.Provider
                        value={{ areAdvancedFiltersSelected, areFavoriteFiltersSelected, mapFilters, updateAreAdvancedFiltersSelected, updateAreFavoriteFiltersSelected, updateMapFilters }}
                      >
                        <MapLegendContext.Provider value={{ hiddenLegendMapId, updateHiddenLegendMapId, needRefreshLegendPosition, setNeedRefreshLegendPosition }}>
                          <ActiveLayerContext.Provider value={{ activeLayer: currentLayer, setActiveLayer: setCurrentLayer }}>
                            <AppLayout
                              headerConf={{
                                title: {
                                  label: t('map:title'),
                                  icon: 'map-o',
                                  subLabel: title,
                                },
                              }}
                              isLoading={isLoading()}
                              loadingMessages={getLoadingMessages()}
                            >
                              <div
                                data-testid='map-page'
                                className='flex flex-col h-full w-full'
                              >
                                <MapHeader
                                  contentKey={sidebarContentKey}
                                  toggleMapSideBar={toggleMapSideBar}
                                  isSidebarOpened={isSidebarOpened}
                                  onExport={onExport}
                                  updateCenter={(center: [number, number]) =>
                                    setCurrentCenter(center)
                                  }
                                  updateZoom={(zoom: number) => setCurrentZoom(zoom)}
                                />
                                <div className='flex flex-1 overflow-hidden relative w-full'>
                                  {isSidebarOpened ? (
                                    <MapSidebar
                                      toggleMapSideBar={toggleMapSideBar}
                                      contentKey={sidebarContentKey}
                                      onLayerClick={onLayerClick}
                                      activeLayer={currentLayer}
                                    />
                                  ) : null}
                                  <div className='h-full w-full relative'>
                                    <MapCanvas
                                      ref={mapRef}
                                      layer={currentLayer}
                                      toggleMapSideBar={toggleMapSideBar}
                                      onHomeClick={resetOtherTerritory}
                                      title={title}
                                      currentCenter={currentCenter}
                                      currentZoom={currentZoom}
                                    />
                                    {mapConfig && (
                                      <ExternalTerritoriesMenu
                                        onTerritoryClick={onTerritoryClick}
                                        defaultTerritory={otherTerritory}
                                      />
                                    )}
                                  </div>
                                  {sidebarProjectData.project !== undefined &&
                                    <MapSidebarProject />
                                  }
                                </div>
                              </div>
                            </AppLayout>
                          </ActiveLayerContext.Provider>
                        </MapLegendContext.Provider>
                      </MapFiltersContext.Provider>
                    </MapConfigContext.Provider>
                  </PoisLinksContext.Provider>
                </PoisContext.Provider>
              </LinkTypeContext.Provider>
            </PhaseTypeContext.Provider>
          </PoiTypeContext.Provider>
        </MapContext.Provider>
      </PoiFormPanelContext.Provider>
    </MapSidebarProjectContext.Provider>
  );
};

export default MapScene;
