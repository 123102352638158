// React libs
import React, { FC } from 'react';
import { Field, Form, FormikProps, Formik, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
// Type
import * as Types from './CreateLinkForm.type';
// Components
import Autocomplete from '../../UiKit/Form/Autocomplete/Autocomplete';
import Button from '../../UiKit/Button/Button';
import Select from '../../UiKit/Form/Select/Select'
import TextField from '../../UiKit/Form/TextField/TextField';
import FaIcon from '../../UiKit/Icon/FaIcon/FaIcon';
// Utils
import { getFormPropTypes, } from '../../../Utils/FormUtils';

const CreateLinkForm: FC<Types.IProps> = ({
  defaultValues,
  miscData,
  onFormSubmit,
  validationSchema,
}) => (
  <Formik
    initialValues={defaultValues}
    onSubmit={onFormSubmit}
    validationSchema={validationSchema}
    enableReinitialize
  >
    {(formikProps: FormikProps<any>) => (
      <CreateLinkFormikForm
        {...formikProps}
        miscData={miscData}
      />
    )}
  </Formik>
);

CreateLinkForm.propTypes = getFormPropTypes();

export default CreateLinkForm;

const CreateLinkFormikForm = ({
  miscData,
  isSubmitting
}: FormikProps<any> & any) => {
  // Variables
  const { t } = useTranslation(['common'])
  const { submitForm } = useFormikContext();
  const isPrimaryColor = miscData?.color === 'primary'
  const submitButtonProps: any = miscData?.isNested ? {
    type: 'button',
    onClick: submitForm
  } : {
    type: 'submit'
  }
  const classNames: any = isPrimaryColor
    ? {
      form: '',
      comment: {
        text: 'text-selection',
        border: 'border-selection',
        color: 'secondary'
      },
      button: {
        color: 'secondary'
      },
      resourceField: {
        color: 'secondary'
      }
    }
    : {
      form: 'bg-selection text-selection-inverse',
      comment: {
        text: 'text-selection-inverse',
        border: 'border-selection-inverse',
        color: 'primary'
      },
      button: {
        color: 'secondary'
      },
      resourceField: {
        color: 'primary'
      }
    }

  return <Form className={`h-full w-full p-2 ${classNames.form}`}>
    <div className='h-9.5/10 w-full'>
      <div>
        <Field
          name='linkType'
          component={Select}
          options={miscData?.linkTypes ?? []}
          label={`${t('common:forms.fields.linkType')}:`}
        />
      </div>
      {miscData?.showResourceField &&
        <div className='my-2'>
          <Field
            name='resource'
            component={Autocomplete}
            color={classNames.resourceField.color}
            options={miscData?.resources ?? []}
            label={`${t('common:forms.fields.Resources')}:`}
          />
        </div>
      }
      <Field
        name='comment'
        component={TextField}
        className='mt-2 p-2'
        InputProps={{
          className: classNames.comment.text,
          classes: {
            notchedOutline: classNames.comment.border
          }
        }}
        rows="2"
        multiline
        label={t('common:forms.fields.comment')}
        color={classNames.comment.color}
        variant='outlined'
      />
    </div>
    <div className='h-0.5/10 w-full text-center'>
      <Button
        className='hover:bg-active-hover mt-1'
        color={classNames.button.color}
        size='small'
        disabled={isSubmitting}
        startIcon={
          isSubmitting ? <FaIcon name='spinner' className='fa-spin' /> : <FaIcon name='check-circle' className='text-lg' />
        }
        {...submitButtonProps}
      >{t('common:forms.fields.apply')}</Button>
    </div>
  </Form>
}