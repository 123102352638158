import { LocaleManager } from '@bryntum/taskboard/taskboard.umd.js'

const locale = {
  localeName: 'FR',
  localeDesc: 'Francais',

  DateHelper: {
    locale: 'fr',
  },

  NumberFormat: {
    locale: 'fr'
  },

  GridBase: {
    loadFailedMessage: 'Échec du chargement des données !',
    syncFailedMessage: 'Échec de la synchronisation des données !',
  },

  Field: {
    fieldRequired: 'Ce champ est requis',
    invalidValue: 'Valeur invalide',
  },

  DateField: {
    invalidDate: 'Date invalide'
  },

  TodoListField: {
    add: 'Ajouter une sous-tâche',
    newTodo: 'Sous-tâche'
  },

  TaskBoard: {
    addTask: 'Ajouter une tâche',
    removeTask: 'Supprimer la tâche',
    editTask: 'Modifier la tâche',
    name: 'Nom',
    description: 'Description',
    resources: 'Contributeurs',
    color: 'Couleur',
    column: 'Status',
    comment: 'Commentaire',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    newTaskName: 'Nouvelle tâche',
    subTasks: 'Sous-tâches',
    save: 'Enregistrer',
    cancel: 'Annuler'
  }
}

export default locale;

LocaleManager.registerLocale('FR', { desc: 'Francais', locale: locale });
