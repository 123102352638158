// React libs
import React, { FC, useContext } from 'react';
import { Field, Form, FormikProps, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
// Components
import Button from '../../../../../Core/Components/UiKit/Button/Button';
import Checkbox from '../../../../../Core/Components/UiKit/Form/Checkbox/Checkbox';
import FaIcon from '../../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import HelperTooltip from '../../../../../Core/Components/UiKit/Form/HelperTooltip/HelperTooltip';
import TextField from '../../../../../Core/Components/UiKit/Form/TextField/TextField';
import Typography from '../../../../../Core/Components/UiKit/Typography/Typography';
import Separator from '../../../../../Core/Components/UiKit/Separator/Separator';
import FileUpload from '../../../../../Core/Components/UiKit/Form/FileUpload/FileUpload';
// Contexts
import MapConfigContext, {
  IMapConfigContext,
} from '../../../Data/Contexts/MapConfigContext';
// Type
import * as Types from './LayerForm.type';
// Utils
import {
  getFormPropTypes,
  isDisabled,
} from '../../../../../Core/Utils/FormUtils';

const LayerForm: FC<Types.IProps> = ({
  defaultValues,
  miscData,
  miscFunctions,
  onFormSubmit,
  validationSchema,
}) => (
  <Formik
    initialValues={defaultValues}
    onSubmit={onFormSubmit}
    validationSchema={validationSchema}
  >
    {(formikProps: FormikProps<any>) => (
      <LayerFormikForm
        {...formikProps}
        miscData={miscData}
        miscFunctions={miscFunctions}
      />
    )}
  </Formik>
);

LayerForm.propTypes = getFormPropTypes();

export default LayerForm;

const LayerFormikForm = ({
  errors,
  isSubmitting,
  touched,
  miscData,
  miscFunctions,
  values,
  setFieldTouched,
  setFieldValue,
  handleBlur,
}: FormikProps<any> & any) => {
  // Variables
  const { t } = useTranslation(['common', 'admin']);
  const addPhotoBtnLabel = (
    <div className='flex items-center'>
      <FaIcon name='camera' />
    </div>
  );
  const deletePhotoBtnLabel = (
    <div className='flex items-center'>
      <FaIcon name='trash' />
    </div>
  );

  // Contexts
  const { mapConfig }: IMapConfigContext = useContext(MapConfigContext);

  // Handlers
  const generatePreview = (e: any) => {
    handleBlur(e);
    const url = values.url;
    const preview = values.thumbnailUrl;
    if (url && (preview === '' || !preview)) {
      let value = url
        .replace('{s}', 'a')
        .replace('{x}', 32)
        .replace('{y}', 22)
        .replace('{z}', 6);
      if (mapConfig && !value.includes('access_token')) {
        value = `${value}?access_token=${mapConfig.mapConfigLayerToken}`;
      }
      setFieldValue('thumbnailUrl', value);
      setFieldTouched('thumbnailUrl', true);
    }
  };

  return (
    <Form data-testid='layer-form'>
      <div className='mt-8'>
        <Typography variant='h5'>
          {t('admin:layers.form.overall.title')}
        </Typography>
        <div className='flex items-center mt-2'>
          <Field
            id='name'
            name='name'
            component={TextField}
            label={t('admin:layers.form.overall.name.label')}
            color='secondary'
          />
        </div>
        <div className='flex items-center mt-2'>
          <Field
            id='thematic'
            name='thematic'
            component={TextField}
            label={t('admin:layers.form.overall.thematic.label')}
            color='secondary'
          />
        </div>
        <div className='flex items-center mt-2'>
          <Field
            id='subThematic'
            name='subThematic'
            component={TextField}
            label={t('admin:layers.form.overall.subThematic.label')}
            color='secondary'
          />
        </div>
        <div className='flex items-center mt-2'>
          <Field
            id='order'
            name='order'
            component={TextField}
            type='number'
            label={t('admin:layers.form.overall.order.label')}
            color='secondary'
          />
        </div>
        <div className='flex items-center mt-2'>
          <Field
            id='default'
            name='default'
            component={Checkbox}
            label={t('admin:layers.form.overall.default.label')}
            color='primary'
          />
        </div>
      </div>
      <Separator type='horizontal' />
      <div className='mt-8'>
        <Typography variant='h5'>
          {t('admin:layers.form.tile.title')}
        </Typography>
        <div className='flex items-center mt-2'>
          <Field
            id='url'
            name='url'
            component={TextField}
            label={t('admin:layers.form.tile.url.label')}
            color='secondary'
            onBlur={generatePreview}
          />
          <HelperTooltip
            message={t('admin:layers.form.tile.url.tooltip', {
              interpolation: { escapeValue: false },
            })}
          />
        </div>
        <div className='flex items-center mt-2'>
          <Field
            id='thumbnailUrl'
            name='thumbnailUrl'
            component={TextField}
            label={t('admin:layers.form.tile.thumbnailUrl.label')}
            color='secondary'
            onBlur={generatePreview}
          />
          <HelperTooltip
            message={t('admin:layers.form.tile.thumbnailUrl.tooltip', {
              interpolation: { escapeValue: false },
            })}
          />
        </div>
      </div>
      <Separator type='horizontal' />
      <div className='mt-8'>
        <Typography variant='h5'>
          {t('admin:layers.form.legend.title')}
        </Typography>
        <div className='mt-4'>
          <Typography variant='h6'>
            {t('admin:layers.form.legend.analyze.title')}
          </Typography>
          <div className='flex items-center mt-2'>
            <Field
              id='legendanalysisLabel'
              name='legendanalysisLabel'
              component={TextField}
              label={t('admin:layers.form.legend.analyze.label.label')}
              color='secondary'
            />
          </div>
          <div className='flex items-center mt-2'>
            <Field
              id='legendanalysisImage'
              name='legendanalysisImage'
              component={FileUpload}
              label={addPhotoBtnLabel}
              deleteLabel={deletePhotoBtnLabel}
              color='secondary'
              defaultFiles={miscData && miscData.legendImages['analysis']}
              onDeleteFile={(urls: string[]) =>
                miscFunctions?.onDeleteImage(
                  urls,
                  'analysis',
                  miscData?.legendImages?.fklayerMapId[0]
                )
              }
            />
          </div>
        </div>
        <div className='mt-4'>
          <Typography variant='h6'>
            {t('admin:layers.form.legend.method.title')}
          </Typography>
          <div className='flex items-center mt-2'>
            <Field
              id='legendmethodLabel'
              name='legendmethodLabel'
              component={TextField}
              label={t('admin:layers.form.legend.method.label.label')}
              color='secondary'
            />
          </div>
          <div className='flex items-center mt-2'>
            <Field
              id='legendmethodImage'
              name='legendmethodImage'
              component={FileUpload}
              label={addPhotoBtnLabel}
              deleteLabel={deletePhotoBtnLabel}
              color='secondary'
              defaultFiles={miscData && miscData.legendImages['method']}
              onDeleteFile={(urls: string[]) =>
                miscFunctions?.onDeleteImage(
                  urls,
                  'method',
                  miscData?.legendImages?.fklayerMapId[0]
                )
              }
            />
          </div>
        </div>
        <div className='mt-4'>
          <Typography variant='h6'>
            {t('admin:layers.form.legend.knowMore.title')}
          </Typography>
          <div className='flex items-center mt-2'>
            <Field
              id='legendknowMoreLabel'
              name='legendknowMoreLabel'
              component={TextField}
              label={t('admin:layers.form.legend.knowMore.label.label')}
              color='secondary'
            />
          </div>
          <div className='flex items-center mt-2'>
            <Field
              id='legendknowMoreImage'
              name='legendknowMoreImage'
              component={FileUpload}
              label={addPhotoBtnLabel}
              deleteLabel={deletePhotoBtnLabel}
              color='secondary'
              defaultFiles={miscData && miscData.legendImages['knowMore']}
              onDeleteFile={(urls: string[]) =>
                miscFunctions?.onDeleteImage(
                  urls,
                  'knowMore',
                  miscData?.legendImages?.fklayerMapId[0]
                )
              }
            />
          </div>
        </div>
      </div>
      <div className='flex items-center justify-center mt-8'>
        <Button
          variant='text'
          size='large'
          onClick={() => miscFunctions?.onEditDone(false)}
        >
          {t('admin:layers.form.validation.cancel.label')}
        </Button>
        <Button
          type='submit'
          variant='text'
          size='large'
          disabled={isDisabled(errors, isSubmitting, touched)}
          startIcon={
            isSubmitting ? <FaIcon name='spinner' className='fa-spin' /> : null
          }
        >
          {isSubmitting
            ? t('admin:layers.form.validation.submit.label.submitting')
            : t('admin:layers.form.validation.submit.label.normal')}
        </Button>
      </div>
    </Form>
  );
};
