// React libs
import React, { FC, useState, useEffect, Fragment, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  Collapse,
  Box,
  TableSortLabel,
  InputAdornment,
  Tooltip,
  Toolbar,
  withStyles,
} from '@material-ui/core';
// Components
import Button from '../Button/Button';
import Checkbox from '../Form/Checkbox/Checkbox';
import DeleteButton from '../Button/DeleteButton/DeleteButton';
import FaIcon from '../Icon/FaIcon/FaIcon';
import FilterButton from '../Button/FilterButton/FilterButton';
import TextField from '../Form/TextField/TextField';
import Typography from '../Typography/Typography';
// Type
import * as Types from './DataTable.type';
// Utils
import { ifDef, removeArrayValue } from '../../../Utils/Misc';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#4A5C63',
    color: '#f5f5f5',
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

interface ITooltipContent {
  content: any
  canTruncate?: boolean
}

const TooltipContent = ({ content, canTruncate = false }: ITooltipContent) => {
  return typeof content === 'string' || canTruncate
    ? <Tooltip title={content}>
      <div className='truncate max-w-40 whitespace-nowrap'>
        {content}
      </div>
    </Tooltip>
    : <>{content}</>
}

interface ITableTreeRow {
  row: Types.ITableRow
  color: 'primary' | 'secondary'
  columns: Types.ITableColumn[]
  level?: number
  multipleSelection?: {
    selectedItems: (string | number)[]
    selectItem: (id: string | number, value: boolean) => void
  }
  expendedItems: { [id: string]: boolean }
  toggleItemExpanded: (id: string, expanded: boolean) => void
}

const TableTreeRow: FC<ITableTreeRow> = ({ row, color, columns, level = 0, multipleSelection, expendedItems, toggleItemExpanded }) => {
  // Variables
  const TableCellComponent = color === 'secondary' ? StyledTableCell : TableCell
  const TableRowComponent = color === 'secondary' ? StyledTableRow : TableRow
  const space = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
  const children = row.children ?? []
  const isExpanded = expendedItems[row.id] ?? false
  // handler
  const toggleExpanded = useCallback(() => toggleItemExpanded((row.id as string), !isExpanded), [isExpanded, row.id, toggleItemExpanded])
  const recursiveSelect = useCallback((value: boolean, subChildren: Types.ITableRow[]) => {
    if (multipleSelection === undefined || !(subChildren?.length > 0)) {
      return
    }

    subChildren.forEach((row: any) => {
      multipleSelection.selectItem(row.id, value)
      recursiveSelect(value, row.children)
    })
  }, [multipleSelection])

  return <>
    <TableRowComponent>
      {multipleSelection && <TableCellComponent padding="checkbox">
        <Checkbox
          field={{
            name: 'selectItem',
            value: multipleSelection.selectedItems.includes(row.id)
          }}
          form={{
            setFieldValue(name: string, value: boolean) {
              multipleSelection.selectItem(row.id, value)
              recursiveSelect(value, children)
            },
            errors: [],
            touched: [],
            setFieldTouched() { }
          }}
          color='primary'
        />
      </TableCellComponent>}
      {columns.map((column: Types.ITableColumn, key: number) => (
        <TableCellComponent
          align={column.align ?? 'left'}
          key={`${row.id}-${column.id}`}
        >
          <div className='flex'>
            {key === 0 && <>
              <div dangerouslySetInnerHTML={{ __html: space.repeat(level) }} />
              <div className='w-6'>
                {children.length > 0 && <IconButton
                  aria-label='expand row'
                  className='mr-1'
                  size='small'
                  onClick={toggleExpanded}
                >
                  <FaIcon name={isExpanded ? 'chevron-up' : 'chevron-down'} className='text-xs' />
                </IconButton>}
              </div>
            </>}
            {column.renderCell ? column.renderCell(row) : row[column.field]}
          </div>
        </TableCellComponent>
      ))}
    </TableRowComponent>
    {children.length > 0 && isExpanded &&
      children.map(row => <TableTreeRow
        row={row}
        key={row.id}
        columns={columns}
        color={color}
        level={level + 1}
        multipleSelection={multipleSelection}
        expendedItems={expendedItems}
        toggleItemExpanded={toggleItemExpanded}
      />)
    }
  </>
}

const ActionButton = ({ tooltip, handler, label, className, size }: Types.actionButtonType) => {
  const button = <Button variant='outlined' onClick={handler} className={className} size={size}>
    {label}
  </Button>
  return ifDef(
    tooltip,
    (tooltip: string) => <Tooltip title={tooltip}>
      <span>{button}</span>
    </Tooltip>,
    () => button
  )
}

const DataTable: FC<Types.ITableProps> = ({
  treeOptions,
  onDeleteAllRows,
  onDeleteMultipleRows = onDeleteAllRows,
  onFilter,
  areFiltersEnabled,
  isFiltersPanelOpened,
  searchColumnKeys,
  columns,
  containerClassName = 'tabledata-container',
  isCollapsible,
  getRowDetails,
  onCreate,
  actionButtons,
  createButtonTooltip,
  rows: initialRows,
  onRowDblClick,
  defaultSortColumnId,
  color = 'secondary',
}) => {
  // Variables
  const TableCellComponent = color === 'secondary' ? StyledTableCell : TableCell
  const TableRowComponent = color === 'secondary' ? StyledTableRow : TableRow
  const { t } = useTranslation(['components']);
  const rowsPerPageValues: any[] = [10, 50, 100];
  const isTree = treeOptions !== undefined

  // State
  const [page, setPage] = useState(0);
  const [selectedItems, setSelectedItems] = useState<(string | number)[]>([])
  const [submittedSearchValue, setSubmittedSearchValue] = useState<string>('')
  const [rowsPerPage, setRowsPerPage]: [number, Function] = useState<number>(
    rowsPerPageValues[1]
  );
  const [openedLines, setOpenedLines]: [
    { [key: string]: boolean },
    Function
  ] = useState({});
  const [orderedBy, setOrderedBy]: [string | undefined, Function] = useState<
    string | undefined
  >(defaultSortColumnId);
  const [orderDirection, setOrderDirection]: [
    Types.TOrder,
    Function
  ] = useState<Types.TOrder>('asc');

  // Derived data
  const { normalRows = [], stickyRows = [] } = useMemo(() => groupBy(initialRows, r => r.stickyRow ? 'stickyRows' : 'normalRows'), [initialRows])
  const normalRowsDep = JSON.stringify(normalRows)
  const rows = useMemo(() => submittedSearchValue.trim() === ''
    ? normalRows
    : normalRows.filter(row =>
      Object.entries(row).some(([key, value]) => {
        if (
          (searchColumnKeys !== undefined && !searchColumnKeys.includes(key)) ||
          (!['string', 'number', 'boolean'].includes(typeof value))
        ) {
          return false
        }
        if (typeof value !== 'string') {
          value = String(value)
        }
        return value.toLowerCase().includes(submittedSearchValue.toLowerCase())
        // eslint-disable-next-line react-hooks/exhaustive-deps
      })), [normalRowsDep, searchColumnKeys, submittedSearchValue]
  )
  const displayCheckboxes = useMemo(() => onDeleteMultipleRows !== undefined, [onDeleteMultipleRows])
  const displayFilters = useMemo(() => onFilter !== undefined, [onFilter])
  const areAllItemsSelected = useMemo(() => {
    if (!(rows?.length > 0)) {
      return false
    }

    let nRows = 0
    if (isTree) {
      const getSize = (rows: Types.ITableRow[] | undefined) => {
        if (rows === undefined || rows.length === 0) {
          return
        }

        nRows += rows.length
        rows.forEach(row => getSize(row.children))
      }
      getSize(rows)
    } else {
      nRows = rows.length
    }
    return selectedItems.length === nRows
  }, [isTree, rows, selectedItems.length])

  // Effects
  useEffect(() => {
    setSelectedItems([])
  }, [rows.length])
  useEffect(() => {
    const updateOpenTabs = () => {
      const list: { [key: string]: boolean } = {};
      rows.forEach((r: Types.ITableRow) => {
        list[r.id] = false;
      });
      setOpenedLines(list);
    };
    updateOpenTabs();
  }, [rows]);
  useEffect(() => {
    const updateOrderedBy = () => {
      columns.forEach((c: Types.ITableColumn) => {
        if (c.orderBy) {
          setOrderedBy(c.id);
          setOrderDirection(c.orderBy);
        }
      });
    };
    updateOrderedBy();
  }, [columns]);

  // Handlers
  const onSearch = useCallback((searchValue: string) => {
    setSubmittedSearchValue(searchValue)
  }, [])
  const ActionsComponent = useCallback(
    props => <TablePaginationActions {...props} actionButtons={actionButtons} createButtonTooltip={createButtonTooltip} onCreate={onCreate} onSearch={onSearch} />,
    [actionButtons, createButtonTooltip, onCreate, onSearch]
  )
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const toggleLine = (id: string | number) => {
    const list: { [key: string]: boolean } = Object.assign({}, openedLines);
    list[id] = !list[id];
    setOpenedLines(list);
  };
  const createSortHandler = (id: string | number) => {
    const isAsc = orderedBy === id && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderedBy(id);
  };

  const selectAllItems = useCallback((value: boolean) => {
    let selectedItems: (string | number)[] = []
    if (value) {
      if (isTree) {
        const recursiveSelect = (rows: Types.ITableRow[] | undefined) => {
          if (rows === undefined || rows.length === 0) {
            return
          }

          rows.forEach(row => {
            selectedItems.push(row.id)
            recursiveSelect(row.children)
          })
        }
        recursiveSelect(rows)
      }
      else {
        selectedItems = rows.map(row => row.id)
      }
    }

    setSelectedItems(selectedItems)
  }, [isTree, rows])
  const selectItem = useCallback((id: string | number, value: boolean) => {
    setSelectedItems(values => value ? (!values.includes(id) ? values.concat(id) : values) : removeArrayValue(values, id))
  }, [])

  // Utils
  const descendingComparator = (
    a: any,
    b: any,
    orderBy: string | undefined
  ) => {
    if (!orderBy) return 0;

    a = typeof a[orderBy] === 'string' ? a[orderBy].toLowerCase() : a[orderBy]
    b = typeof b[orderBy] === 'string' ? b[orderBy].toLowerCase() : b[orderBy]

    if (a === b) return 0
    if (a == null || b < a) {
      return -1;
    }
    if (b == null || b > a) {
      return 1;
    }
    return 0;
  };

  const getComparator = (
    order: Types.TOrder,
    orderBy: string | undefined
  ): ((a: { key: number | string }, b: { key: number | string }) => number) =>
    order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  const stableSort = (array: any[], comparator: (a: any, b: any) => number) => {
    const stabilizedThis = array.map(
      (el, index) => [el, index] as [any, number]
    );
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  };

  return (
    <div data-testid='data-table' className='h-full w-full'>
      {(displayCheckboxes || displayFilters) &&
        <Toolbar className='bg-white flex justify-between' variant='dense'>
          <div className='flex items-center'>
            {displayCheckboxes &&
              <Typography color="inherit" variant="subtitle2">{t('components:datatable.selected', { nSelected: selectedItems.length })}</Typography>
            }
            {displayFilters && <FilterButton
              onClick={() => onFilter?.()}
              isActive={areFiltersEnabled ?? false}
              isFiltersPanelOpened={isFiltersPanelOpened ?? false}
              tooltip={t(`components:datatable.${areFiltersEnabled ? 'enabledFilters' : 'disabledFilters'}`)}
              label={t('components:datatable.filters')}
              className='ml-1'
            />}
          </div>
          <div />
          <div>
            {displayCheckboxes &&
              <DeleteButton size='small' disabled={selectedItems.length === 0} tooltip={t('components:datatable.deleteAll')} onClick={() => onDeleteMultipleRows?.(selectedItems)} />
            }
          </div>
        </Toolbar>
      }
      <TableContainer component={Paper} className={containerClassName}>
        <Table size='small' aria-label='a dense table' stickyHeader>
          <TableHead>
            <TableRowComponent>
              {displayCheckboxes &&
                <TableCellComponent padding="checkbox">
                  <Checkbox
                    field={{
                      name: 'selectAllItems',
                      value: areAllItemsSelected
                    }}
                    disabled={rows.length === 0}
                    form={{
                      setFieldValue(name: string, value: boolean) {
                        selectAllItems(value)
                      },
                      errors: [],
                      touched: [],
                      setFieldTouched() { }
                    }}
                    color={color}
                  />
                </TableCellComponent>
              }
              {isCollapsible && <TableCellComponent />}
              {columns &&
                columns.map((c: Types.ITableColumn) => (
                  <TableCellComponent
                    align={c.headerAlign ? c.headerAlign : 'left'}
                    key={c.id}
                    sortDirection={orderedBy === c.id ? orderDirection : false}
                  >
                    <TableSortLabel
                      classes={{
                        root: color === 'secondary' ? 'hover:text-active-hover' : '',
                        active: color === 'secondary' ? 'text-active hover:text-active-hover' : '',
                        icon: color === 'secondary' ? 'text-active hover:text-active-hover' : '',
                      }}
                      active={orderedBy === c.id}
                      direction={orderedBy === c.id ? orderDirection : 'asc'}
                      onClick={() => createSortHandler(c.id)}
                    >
                      <TooltipContent content={c.name} />
                    </TableSortLabel>
                  </TableCellComponent>
                ))}
            </TableRowComponent>
          </TableHead>
          <TableBody>
            {rows &&
              stableSort(rows, getComparator(orderDirection, orderedBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((r: Types.ITableRow) =>
                  treeOptions !== undefined
                    ? <TableTreeRow
                      key={r.id}
                      row={r}
                      color={color}
                      columns={columns}
                      multipleSelection={displayCheckboxes ? {
                        selectedItems,
                        selectItem
                      } : undefined}
                      {...treeOptions}
                    />
                    : (
                      <Fragment key={r.id}>
                        <TableRowComponent
                          onClick={() => toggleLine(r.id)}
                          onDoubleClick={() => {
                            if (onRowDblClick !== undefined) onRowDblClick(r)
                          }}
                          className='cursor-pointer'
                        >
                          {displayCheckboxes &&
                            <TableCellComponent padding="checkbox">
                              <Checkbox
                                field={{
                                  name: 'selectItem',
                                  value: selectedItems.includes(r.id)
                                }}
                                form={{
                                  setFieldValue(name: string, value: boolean) {
                                    selectItem(r.id, value)
                                  },
                                  errors: [],
                                  touched: [],
                                  setFieldTouched() { }
                                }}
                                color='primary'
                              />
                            </TableCellComponent>
                          }
                          {isCollapsible && (
                            <TableCellComponent>
                              <IconButton
                                aria-label='expand row'
                                size='small'
                                onClick={() => toggleLine(r.id)}
                              >
                                {openedLines[r.id] === true ? (
                                  <FaIcon name='chevron-up' />
                                ) : (
                                  <FaIcon name='chevron-down' />
                                )}
                              </IconButton>
                            </TableCellComponent>
                          )}
                          {columns &&
                            columns.map((c: Types.ITableColumn) => (
                              <TableCellComponent
                                align={c.align ? c.align : 'left'}
                                key={`${r.id}-${c.id}`}
                              >
                                <TooltipContent content={c.renderCell ? c.renderCell(r) : r[c.field]} canTruncate={c.canTruncate} />
                              </TableCellComponent>
                            ))}
                        </TableRowComponent>
                        {isCollapsible && (
                          <TableRowComponent>
                            <TableCellComponent
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={columns ? columns.length + 1 : 1}
                            >
                              <Collapse
                                in={openedLines[r.id]}
                                timeout='auto'
                                unmountOnExit
                              >
                                <Box margin={1} className='overflow-x-auto'>
                                  {getRowDetails && getRowDetails(r as any)}
                                </Box>
                              </Collapse>
                            </TableCellComponent>
                          </TableRowComponent>
                        )}
                      </Fragment>
                    ))}
            {submittedSearchValue === '' && stickyRows.map((r: Types.ITableRow) => (
              <TableRowComponent key={r.id}>
                {displayCheckboxes && <TableCellComponent padding="checkbox" />}
                {columns &&
                  columns.map((c: Types.ITableColumn) => (
                    <TableCellComponent
                      align={c.align ? c.align : 'left'}
                      key={`${r.id}-${c.id}`}
                    >
                      {c.renderCell ? c.renderCell(r) : r[c.field]}
                    </TableCellComponent>
                  ))}
              </TableRowComponent>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component='div'
        classes={{ toolbar: 'flex items-center', spacer: 'flex-none' }}
        rowsPerPageOptions={rowsPerPageValues}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} (${count !== -1 ? count : `> ${to}`})`
        }
        labelRowsPerPage={t('components:datatable.labelRowsPerPage')}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={ActionsComponent}
      />
    </div>
  );
};

DataTable.propTypes = {
  columns: PropTypes.arrayOf<Types.ITableColumn>(PropTypes.any).isRequired,
  isCollapsible: PropTypes.bool,
  getRowDetails: PropTypes.func,
  onCreate: PropTypes.func,
  rows: PropTypes.arrayOf<Types.ITableRow>(PropTypes.any).isRequired,
};

DataTable.defaultProps = {
  isCollapsible: false,
};

export default DataTable;

const TablePaginationActions = ({
  actionButtons,
  count,
  page,
  rowsPerPage,
  onChangePage,
  onCreate,
  createButtonTooltip,
  onSearch,
}: Types.ITablePaginationActionsProps) => {
  // Variables
  const { t } = useTranslation(['components']);
  // State
  const [searchValue, setSearchValue] = useState<string>('')

  // Handlers
  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value), [])
  const submitSearch = useCallback(() => {
    onSearch(searchValue)
  }, [onSearch, searchValue])
  const handleSearchKeydown = useCallback((e: any) => {
    if (e.key === 'Enter') {
      submitSearch()
    }
  }, [submitSearch])
  const clearSearchValue = useCallback(() => {
    setSearchValue('')
    onSearch('')
  }, [onSearch])
  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    onChangePage(event, page - 1);
  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    onChangePage(event, page + 1);

  // Effects
  useEffect(() => {
    clearSearchValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='flex flex-1 items-center justify-between mx-2'>
      <div className='flex flex-1'>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label='previous page'
          size='small'
        >
          <FaIcon name='backward' />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label='next page'
          size='small'
        >
          <FaIcon name='forward' />
        </IconButton>
        <div className='flex ml-3 items-center justify-center'>
          <TextField
            field={{ name: 'search', value: searchValue, onChange: handleSearch, onKeyDown: handleSearchKeydown, placeholder: t('components:datatable.search') }}
            form={{}}
            color='secondary'
            InputProps={{
              endAdornment: <>
                {searchValue !== '' &&
                  <InputAdornment position='end'>
                    <FaIcon
                      name='times'
                      onClick={clearSearchValue}
                      className='cursor-pointer'
                    />
                  </InputAdornment>
                }
                <InputAdornment position='end'>
                  <FaIcon
                    name='search'
                    onClick={submitSearch}
                    className='cursor-pointer'
                  />
                </InputAdornment>
              </>
            }}
          />
        </div>
      </div>
      {onCreate && <ActionButton
        tooltip={createButtonTooltip}
        handler={onCreate}
        label={t('components:datatable.add.label')}
        className='ml-2 mr-1'
        size='medium'
      />}
      {actionButtons?.map((actionButton, key) => <ActionButton
        key={key}
        size='medium'
        {...actionButton}
      />)}
    </div>
  );
};
