// React libs
import React, { FC, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { uniqueId } from 'lodash';
// Contexts
import MapContext from '../../Data/Contexts/MapContext';
// Components
import LocalLoader from '../../../../Core/Components/UiKit/Loader/LocalLoader/LocalLoader';
import MapLayer from '../../Components/Canvas/MapLayer/MapLayer';
import Typography from '../../../../Core/Components/UiKit/Typography/Typography';
// Types
import * as Types from './SharedMap.type'
import * as MapTypes from '../../Data/Models/Map.type'
// Services
import MapService from '../../Data/Services/MapService';

const SharedMap: FC<Types.IProps> = ({ location }) => {
  // State
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [mapShare, setMapShare] = useState<MapTypes.IMapSharesPois | undefined>()
  const [map, setMap] = useState<any>()

  // Variables
  const { t } = useTranslation(['map'])
  const query = new URLSearchParams(location?.search)
  const id = query.get('embed')
  const showLegend = query.get('title') === 'true'
  const legend = query.get('legend')
  const mapId = useMemo(() => uniqueId('shared-map'), [])

  //Effects
  useEffect(() => {
    setIsLoading(true)
    MapService.getMapSharesPois(id ?? '').then(
      ({ data }) => {
        // pois
        const markers: MapTypes.IPoi[] = []
        const poiTypes: { [id: string]: MapTypes.IPoiType } = {}
        data.markers.forEach((marker: any) => {
          markers.push({
            ...marker,
            fkpoiType: marker.type.id
          })
          poiTypes[marker.type.id] = marker.type
        })

        // links
        const links: MapTypes.IPoiLink[] = []
        const linkTypes: { [id: string]: MapTypes.ILinkType } = {}
        data.links.forEach((link: any) => {
          links.push({
            ...link,
            fklinkType: link.type.id
          })
          linkTypes[link.type.id] = link.type
        })

        setMapShare({
          ...data,
          markers,
          poiTypes: Object.values(poiTypes),
          links,
          linkTypes: Object.values(linkTypes)
        })
      },
      error => {
        console.error(error)
      }
    ).finally(() => {
      setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div data-testid='shared-map' className='h-full'>
    {isLoading || mapShare === undefined
      ? <div className='flex h-full w-full items-center justify-center bg-map'>
        {isLoading ?
          <LocalLoader message={t('map:shared.loading')} type='contained' />
          : <Typography variant='h1' className='font-extrabold text-center m-auto'>
            {t('map:shared.noMapShared')}
          </Typography>
        }
      </div>
      : <MapContext.Provider value={{ map, setMap, setCurrentZoom: () => { }, setCurrentCenter: () => { } }}>
        <div className='flex flex-1 overflow-hidden relative h-full w-full'>
          <MapLayer
            data={{
              pois: mapShare.markers,
              poisLinks: mapShare.links,
              phaseTypes: mapShare.phaseTypes,
              poiTypes: mapShare.poiTypes,
              linkTypes: mapShare.linkTypes,
              category: mapShare.selection.category
            }}
            containerId={mapId}
            center={mapShare.mapInfos.center}
            zoom={mapShare.mapInfos.zoom}
            layerUrl={mapShare.mapInfos.layer}
          />
          {showLegend &&
            <div className='absolute flex justify-between h-10 w-full bg-main-light mt-2 p-1 z-500'>
              <div className='flex items-center'>
                <img src={`${process.env.PUBLIC_URL}/logo192.png`} alt='logo' height={35} width={35} />
                <Typography variant='h4' className='ml-1'>mia</Typography>
              </div>
              <div className='flex items-center'>
                <Typography variant='h4' className='font-extrabold'>{legend}</Typography>
              </div>
              <div />
            </div>
          }
        </div>
      </MapContext.Provider>
    }
  </div>
}

export default SharedMap