// React libs
import React, { FC, useState, useCallback, useMemo } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash'
// Components
import TextField from '../../../../../../../Core/Components/UiKit/Form/TextField/TextField';
// Types
import * as Types from './MapSearchBar.type'
// Utils
import { advancedFiltersOperators } from '../../../../../../../Core/Utils/Filters'

const MapSearchBar: FC<Types.IProps> = ({ onFilterChange }) => {
  // State
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [type, setType] = useState<string>('name')
  const [filter, setFilter] = useState<string>('')

  // Variables
  const { t } = useTranslation(['map']);
  const options = [
    {
      label: t('map:sidebar.cards.name'),
      value: 'name'
    },
    {
      label: t('map:sidebar.cards.thematic'),
      value: 'thematic'
    },
    {
      label: t('map:sidebar.cards.subThematic'),
      value: 'subThematic'
    }
  ]
  const isMenuOpened = Boolean(anchorEl)
  const label = useMemo(() => t('map:sidebar.cards.searchBy', { type: options.find(_ => _.value === type)?.label.toLocaleLowerCase() }), [options, t, type])

  // handlers
  const openMenu = useCallback(e => {
    setAnchorEl(e.currentTarget)
  }, [])
  const closeMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])
  const debouncedOnFilterChange = useMemo(() => debounce(onFilterChange, 280), [onFilterChange])
  const onTypeChange = useCallback(type => {
    setType(type)
    setFilter('')
    onFilterChange(undefined)
  }, [onFilterChange])
  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    setFilter(value)

    value = value.trim()
    debouncedOnFilterChange(value !== ''
      ? advancedFiltersOperators.like.format({ name: type, value, operator: 'like' }, true)
      : undefined
    )

  }, [debouncedOnFilterChange, type])

  return <div data-testid='map-search-bar' className='mb-4'>
    <TextField
      field={{ name: 'filter', value: filter, onChange }}
      label={label}
      form={{}}
      className='truncate'
      icon={{
        component: isMenuOpened ? 'caret-up' : 'caret-down',
        position: 'end',
        onClick: openMenu,
      }}
    />
    <Menu
      anchorEl={anchorEl}
      open={isMenuOpened}
      onClose={closeMenu}
      PopoverClasses={{
        root: 'z-1600',
      }}
    >
      {options.map(option => <MenuItem key={option.value} onClick={() => {
        onTypeChange(option.value)
        closeMenu()
      }}>{option.label}</MenuItem>)}
    </Menu>
  </div >
}

export default MapSearchBar