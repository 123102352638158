// Utils
import { createContext } from 'react';

export interface IMapContext {
  map: any
  setMap: (map: any) => void;
  setCurrentZoom: Function
  setCurrentCenter: Function
}
const LinkTypeContext = createContext<IMapContext>({
  map: undefined,
  setMap: (map: any) => { },
  setCurrentZoom: (zoom: number) => { },
  setCurrentCenter: (center: [number, number]) => { }
});

export default LinkTypeContext;
