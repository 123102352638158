// React libs
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Components
import FaIcon from '../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
// Type
import * as Types from './AppSidebar.type';
// Common
import Common from '../../../Resources/Common';
import { Tooltip } from '@material-ui/core';

const AppSidebar: FC<Types.IProps> = () => {
  // Variables
  const { t } = useTranslation(['common']);
  const iconClasses = 'fill-current text-2xl';
  const menuConf = [
    {
      id: 'dataviz',
      icon: 'bar-chart',
      link: Common.Routes.dataviz,
      tooltip: t('common:appSidebar.dataviz')
    },
    {
      id: 'map',
      icon: 'map-o',
      link: Common.Routes.routeMap,
      tooltip: t('common:appSidebar.map')
    },
    {
      id: 'resources',
      icon: 'map-marker',
      link: Common.Routes.routeResources,
      tooltip: t('common:appSidebar.resources')
    },
    {
      id: 'projects',
      icon: 'cubes',
      link: Common.Routes.routeProjects,
      tooltip: t('common:appSidebar.projects')
    },
    {
      id: 'planning',
      icon: 'tasks',
      link: Common.Routes.routePlanning,
      tooltip: t('common:appSidebar.planning')
    },
    {
      id: 'contacts',
      icon: 'address-book-o',
      link: Common.Routes.routeContacts,
      isLinkActive: [Common.Routes.routeContacts, Common.Routes.routeCreateContact, Common.Routes.routeUpdateContact].some(p => document.location.pathname.startsWith(`/${p}`)),
      tooltip: t('common:appSidebar.contacts')
    },
  ];

  // Getters
  const isActive = (m: any) => {
    const pathname = document.location.pathname;
    const paths = pathname.split('/');
    const linkElements = m.link.split('/');
    const linkToTest =
      linkElements[0] === '' ? linkElements[1] : linkElements[0];
    let isActive = false;
    if (paths.length > 1) {
      isActive = m.isLinkActive ?? paths[1] === linkToTest;
    }
    return isActive;
  };

  return (
    <div
      className='bg-main-light flex flex-col px-2 py-1 w-16 overflow-auto'
      data-testid='app-sidebar'
    >
      {menuConf.map((m: any) => (
        <Tooltip title={m.tooltip} key={m.id}>
          <span>
            <Link
              to={`/${m.link}`}
              className={`flex items-center justify-center py-2 my-1 cursor-pointer rounded ${isActive(m) ? 'bg-active' : 'hover:bg-active-hover'
                } `}
            >
              <FaIcon
                name={m.icon}
                className={`${iconClasses} ${isActive(m) ? 'text-white' : ''}`}
              />
            </Link>
          </span>
        </Tooltip>
      ))}
    </div>
  );
};

export default AppSidebar;
