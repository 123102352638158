// React libs
import { useMemo } from 'react';
import { groupBy } from 'lodash'
import { LocaleManager } from '@bryntum/scheduler/scheduler.umd.js'
import { useTranslation } from 'react-i18next';
// Components
import { TASK_COLOR_BY_STATUS } from './ProjectTasksManagement'
// Locales
import '../../../../Core/Resources/assets/locales/scheduler-local-fr'

if (navigator.language.includes('fr')) {
  LocaleManager.locale = 'FR'
}

interface IUseSchedulerOptions {
  data: any
  isEditionMode: boolean
  setRecord: (...args: any) => void
}

export default function useSchedulerOptions({ data, setRecord, isEditionMode }: IUseSchedulerOptions) {
  // Variables
  const { t } = useTranslation(["project"])
  const stringData = useMemo(() => JSON.stringify(data), [data])
  const { resources, events }: any = useMemo(() => {
    const dataByStatus = groupBy(data?.map((task: any) => ({
      id: task.id,
      name: task.name,
      status: task.status
    })), 'status')

    return {
      resources: [
        { id: 'todo', name: t('project:tasksManagement.todo'), expanded: true, children: dataByStatus.todo },
        { id: 'doing', name: t('project:tasksManagement.doing'), expanded: true, children: dataByStatus.doing },
        { id: 'done', name: t('project:tasksManagement.done'), expanded: true, children: dataByStatus.done }
      ],
      events: data?.map((task: any, key: number) => ({
        id: key,
        resourceId: task.id,
        eventColor: task.eventColor ?? (TASK_COLOR_BY_STATUS as any)[task.status],
        startDate: task.startDate,
        endDate: task.endDate
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringData])

  const options = useMemo(() => ({
    createEventOnDblClick: false,
    listeners: {
      async eventResizeEnd({ eventRecord }: any) {
        await eventRecord.project.commitAsync()

        const { resourceId, startDate, duration, endDate } = eventRecord.data
        setRecord(resourceId, 'startDate', startDate)
        setRecord(resourceId, 'duration', duration)
        setRecord(resourceId, 'endDate', endDate)
      },
      presetChange() {
        const scheduler: any = this
        const widgets = scheduler.widgetMap
        widgets.zoomOutButton.disabled = scheduler.zoomLevel <= scheduler.minZoomLevel
        widgets.zoomInButton.disabled = scheduler.zoomLevel >= scheduler.maxZoomLevel
      }
    },
    columns: [
      { field: 'name', text: t('project:tasksManagement.name'), width: 100, type: 'tree' }
    ],
    resources,
    events,
    resourceStore: {
      syncDataOnLoad: false
    },
    eventStore: {
      syncDataOnLoad: false
    },
    features: {
      // Enabled
      tree: true,

      // Enabled when editing mode
      eventResize: isEditionMode,

      // Disabled
      timeAxisHeaderMenu: {
        items: {
          eventsFilter: false,
        }
      },
      sort: false,
      cellEdit: false,
      cellMenu: false,
      eventCopyPaste: false,
      eventDrag: false,
      eventDragCreate: false,
      eventEdit: false,
      eventMenu: false,
      scheduleMenu: false,
    },
    tbar: {
      onZoomIn() {
        (this as any).parent.zoomIn()
      },
      onZoomOut() {
        (this as any).parent.zoomOut()
      },
      onPrevious() {
        (this as any).parent.shiftPrevious()
      },
      onNext() {
        (this as any).parent.shiftNext()
      },
      contentElementCls: 'justify-end',
      items: [
        {
          type: 'buttonGroup',
          items: [
            {
              cls: 'b-raised bg-active',
              ref: 'previousButton',
              icon: 'b-fa b-fa-backward',
              tooltip: t('project:tasksManagement.previousTimeSpan'),
              onAction: 'up.onPrevious',
            },
            {
              cls: 'b-raised bg-active',
              ref: 'nextButton',
              icon: 'b-fa b-fa-forward',
              tooltip: t('project:tasksManagement.nextTimeSpan'),
              onAction: 'up.onNext',
            },
            {
              cls: 'b-raised bg-active',
              ref: 'zoomInButton',
              icon: 'b-fa b-fa-search-plus',
              tooltip: t('project:tasksManagement.zoomIn'),
              onAction: 'up.onZoomIn'
            },
            {
              cls: 'b-raised bg-active',
              ref: 'zoomOutButton',
              icon: 'b-fa b-fa-search-minus',
              tooltip: t('project:tasksManagement.zoomOut'),
              onAction: 'up.onZoomOut',
            }
          ]
        }
      ]
    }
  }), [events, isEditionMode, resources, setRecord, t])

  return {
    options
  }
}