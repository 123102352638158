// React libs
import { useMemo, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import { LocaleManager, ProjectModel, TaskModel } from '@bryntum/taskboard/taskboard.umd.js'
import { useTranslation } from 'react-i18next';
// Services
import ProjectUrls from '../../Data/Services/ProjectUrls';
// Types
import * as CoreTypes from '../../../../Core/Data/Models/Core.type'
// Component
import { getStringPreview } from '../../../../Core/Components/Form/FormFields/FormFields';
import { TASK_COLOR_BY_STATUS } from './ProjectTasksManagement'
// Locales
import '../../../../Core/Resources/assets/locales/taskBoard-local-fr'

if (navigator.language.includes('fr')) {
  LocaleManager.locale = 'FR'
}

class CustomTask extends TaskModel {
  static get fields() {
    return [
      {
        name: 'comment',
        type: 'string',
      },
      {
        name: 'checkListTodo',
        type: 'array',
        defaultValue: []
      },
      {
        name: 'startDate',
        type: 'date',
        defaultValue: moment().millisecond(0).second(0).minute(0).hour(0).toDate()
      },
      {
        name: 'endDate',
        type: 'date',
        defaultValue: moment().add('1', 'days').millisecond(0).second(0).minute(0).hour(0).toDate(),
      },
    ];
  }
}

interface IUseTaskBoardOptions {
  project: CoreTypes.IPoi
  isEditionMode: boolean
}

export default function useTaskBoardOptions({ project, isEditionMode }: IUseTaskBoardOptions) {
  // State
  const [data, setData] = useState<any>()

  // Variables
  const { t } = useTranslation(["project"])

  const taskBoardProject = useMemo(() => new ProjectModel({
    autoLoad: true,
    writeAllFields: true,
    transport: {
      load: {
        url: ProjectUrls.getProjectTasks(project.id)
      },
      sync: {
        url: ProjectUrls.updateProjectTasks(project.id),
        method: 'POST'
      }
    },
    taskStore: {
      modelClass: CustomTask,
      listeners: {
        change: () => {
          setData(taskBoardProject.taskStore.allRecords?.map(({ data }: any) => data))
        }
      }
    }
  }), [project.id])

  const options = useMemo(() => ({
    useDomTransition: true,
    stickyHeaders: true,
    showCollapseInHeader: false,
    cls: 'w-full h-full',

    columnField: 'status',
    columns: [
      { id: 'todo', text: t('project:tasksManagement.todo'), color: TASK_COLOR_BY_STATUS.todo },
      { id: 'doing', text: t('project:tasksManagement.doing'), color: TASK_COLOR_BY_STATUS.doing },
      { id: 'done', text: t('project:tasksManagement.done'), color: TASK_COLOR_BY_STATUS.done }
    ],

    headerItems: {
      menu: { type: 'taskMenu', order: 200, style: { color: 'black' } },
    },

    bodyItems: {
      comment: {
        type: 'template',
        template({ taskRecord }: any) {
          const { comment } = taskRecord;

          return comment && {
            html: ReactDOMServer.renderToStaticMarkup(
              (getStringPreview(comment) as any)
            )
          }
        }
      },
      checkListTodo: !isEditionMode
        ? {
          type: 'template',
          template({ taskRecord }: any) {
            const { checkListTodo, status, eventColor = (TASK_COLOR_BY_STATUS as any)[status] } = taskRecord
            return checkListTodo?.map((item: any) => (
              {
                html: item.checkListDone
                  ? `<div class='b-taskboard-todolist-todo b-checked'><i class='b-icon b-icon-checked' /> <span>${item.checkListText}</span></div>`
                  : `<div class='b-taskboard-todolist-todo b-taskboard-color-${eventColor}'><i class='b-icon b-icon-unchecked' /> <span>${item.checkListText}</span></div>`
              }
            ))
          },
        } : {
          type: 'todoList',
          textField: 'checkListText',
          checkedField: 'checkListDone'
        },
      resourceAvatars: {
        type: 'resourceAvatars'
      }
    },

    footerItems: {
      resourceAvatars: false,
      checkListTodo: {
        type: 'template',
        template({ taskRecord }: any) {
          const { checkListTodo } = taskRecord;
          if (checkListTodo == null) {
            return
          }

          const nDone = checkListTodo.filter((t: any) => t.checkListDone).length
          const nItems = checkListTodo.length
          const percentDone = nItems === 0 ? 0 : nDone * 100 / nItems

          return [
            {
              tag: 'i',
              class: 'b-fa b-fa-tasks mr-2'
            },
            `${nDone}/${nItems} (${Math.round(percentDone)} %)`
          ];
        }
      },
      startDate: {
        type: 'template',
        template({ taskRecord }: any) {
          const { startDate, endDate } = taskRecord;
          const dateFormat = 'DD/MM/YYYY'
          return {
            html: `<span>${t('project:tasksManagement.from')} ${moment(startDate).format(dateFormat)} ${t('project:tasksManagement.to')} ${moment(endDate).format(dateFormat)}`,
            class: 'text-selection absolute right-2'
          }
        }
      }
    },

    features: {
      columnHeaderMenu: false,
      columnDrag: false,
      taskMenu: {
        disabled: !isEditionMode,
        items: {
          editTask: {
            icon: 'b-fa b-fa-pencil-alt',
          },
          resources: false,
          column: false
        }
      },
      taskDrag: isEditionMode,
      columnToolbars: {
        disabled: !isEditionMode,
      },
      taskEdit: {
        processItems({ items, taskRecord }: any) {
          items.endDate.min = taskRecord.data.startDate
        },
        editorConfig: {
          autoUpdateRecord: false,
          saveAndCloseOnEnter: false,
          bbar: {
            items: {
              cancelButton: {
                weight: 18,
                text: 'L{TaskBoard.cancel}',
              },
              saveButton: {
                weight: 19,
                text: 'L{TaskBoard.save}',
              },
            },
            contentElementCls: 'justify-center'
          }
        },
        disabled: !isEditionMode,
        items: {
          description: false,
          startDate: {
            type: 'date',
            name: 'startDate',
            label: 'L{TaskBoard.startDate}',
            weight: 10,
            required: true,
            listeners: {
              change({ value }: any) {
                const taskEditor = (this as any).parent

                const endField = taskEditor.items.find((item: any) => item.ref === 'endDate')
                endField.min = value

                const startTime = new Date(value).getTime()
                const endTime = new Date(taskEditor.record.data.endDate).getTime()
                if (startTime > endTime) {
                  endField.value = value
                }
              }
            }
          },
          endDate: {
            type: 'date',
            name: 'endDate',
            label: 'L{TaskBoard.endDate}',
            weight: 11,
            required: true
          },
          name: {
            required: true,
            weight: 12,
          },
          resources: {
            weight: 13,
            multiSelect: false
          },
          color: {
            weight: 14
          },
          column: false,
          comment: {
            weight: 16,
            type: 'textArea',
            name: 'comment',
            label: 'L{TaskBoard.comment}',
          },
          checkListTodo: {
            weight: 17,
            type: 'todolist',
            name: 'checkListTodo',
            label: 'L{TaskBoard.subTasks}',
            textField: 'checkListText',
            checkedField: 'checkListDone',
          }
        }
      }
    },
    project: taskBoardProject
  }), [isEditionMode, t, taskBoardProject])

  return {
    data,
    async onSubmit() {
      try {
        await taskBoardProject.sync()
        await taskBoardProject.load()
      } catch (error) {
        await taskBoardProject.revertChanges()
        throw error
      }
    },
    onCancel() {
      return taskBoardProject.revertChanges()
    },
    setRecord(recordId: string, field: string, value: any) {
      const record = taskBoardProject.taskStore.allRecords?.find(({ data }: any) => data.id === recordId)
      if (record !== undefined) {
        record.set(field, value)
      }
    },
    options
  }
}