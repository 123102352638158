// React libs
import React, { FC, useMemo, ComponentProps, useCallback, Fragment, useState } from 'react';
import moment from 'moment';
import urlRegexSafe from 'url-regex-safe'
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener, Grid } from '@material-ui/core'
import { groupBy, mapValues, sortBy } from 'lodash';
// Components
import Autocomplete from '../../UiKit/Form/Autocomplete/Autocomplete';
import Button from '../../UiKit/Button/Button';
import CheckboxGroup from '../../UiKit/Form/CheckboxGroup/CheckboxGroup';
import DatePicker from '../../UiKit/Form/DatePicker/DatePicker';
import FaIcon from '../../UiKit/Icon/FaIcon/FaIcon';
import FileUpload from '../../UiKit/Form/FileUpload/FileUpload';
import FormTitle from '../FormTitle/FormTitle';
import RadioGroup from '../../UiKit/Form/RadioGroup/RadioGroup';
import TextField from '../../UiKit/Form/TextField/TextField';
import ThreeColorsRadio, { ColorRadioByValue } from '../../UiKit/Form/ThreeColorsRadio/ThreeColorsRadio'
// Hooks
import useAccessTypes from '../../../Data/Hooks/AccessTypes';
import useAddresses from '../../../Data/Hooks/Addresses'
import useLinkTypes from '../../../Data/Hooks/LinkTypes'
import usePersons from '../../../Data/Hooks/Persons'
import usePoiTypes from '../../../Data/Hooks/PoiTypes'
import useTerritories from '../../../Data/Hooks/Territories'
import useThematic from '../../../Data/Hooks/Thematic'
// Types
import * as CoreTypes from '../../../Data/Models/Core.type'
// Utils
import { momentToISOString } from '../../../Utils/Misc';
import { formatPersonName } from '../../../Utils/FormUtils';

const COMMON_CLASSNAME = 'flex w-full items-center flex-1 mb-5'

interface IFormPoiType extends ComponentProps<'div'> {
  name: string
  disabled?: boolean
  type: 'project' | 'resource'
  onPoiTypeChange?: (poiType: string) => Promise<boolean> | boolean
}

export const FormPoiType: FC<IFormPoiType> = ({ name, type, onPoiTypeChange, disabled = false, className = COMMON_CLASSNAME }) => {
  // Variables
  const { t } = useTranslation(['common']);
  const poiTypes = usePoiTypes(type)
  const options = useMemo(() => poiTypes.data != null ? poiTypes.data.map((type: CoreTypes.IPoiType) => ({
    label: type.label,
    value: type.id
  })) : [], [poiTypes.data])

  return <div className={className}>
    <Field
      id={name}
      name={name}
      component={Autocomplete}
      options={options}
      label={t('common:forms.fields.type')}
      color='secondary'
      className='flex-1'
      onChangePredicate={onPoiTypeChange}
      disabled={disabled}
    />
  </div>
}

interface IFormName extends ComponentProps<'div'> {
  name: string
  disabled?: boolean
}

export const FormName: FC<IFormName> = ({ name, disabled, className = COMMON_CLASSNAME }) => {
  // Variables
  const { t } = useTranslation(['common']);

  return <div className={className}>
    <Field
      id={name}
      name={name}
      component={TextField}
      label={t('common:forms.fields.name')}
      color='secondary'
      className='flex-1'
      disabled={disabled}
    />
  </div >
}

interface IFormThematic extends ComponentProps<'div'> {
  name: string
  disabled?: boolean
}

export const FormThematic: FC<IFormThematic> = ({ name, disabled, className = COMMON_CLASSNAME }) => {
  // Variables
  const { t } = useTranslation(['common']);
  const thematic = useThematic()
  const options = useMemo(() => thematic.data != null ? thematic.data.map((thematic: CoreTypes.IThematic) => ({
    label: thematic.label,
    value: thematic.id
  })) : [], [thematic.data])

  return <div className={className}>
    <Field
      id={name}
      name={name}
      component={Autocomplete}
      options={options}
      label={t('common:forms.fields.thematic')}
      color='secondary'
      className='flex-1'
      multiple
      disabled={disabled}
    />
  </div >
}

interface IFormLinkTypes extends ComponentProps<'div'> {
  name: string
  label?: string
  disabled?: boolean
}

export const FormLinkTypes: FC<IFormLinkTypes> = ({ name, label, disabled, className }) => {
  // Variables
  const linkTypes = useLinkTypes()
  const options = useMemo(() => linkTypes.data != null ? linkTypes.data.map((linkType: CoreTypes.ILinkType) => ({
    label: linkType.label,
    value: linkType.id
  })) : [], [linkTypes.data])

  return <div className={className}>
    <Field
      id={name}
      name={name}
      component={Autocomplete}
      options={options}
      label={label}
      color='secondary'
      className='flex-1'
      disabled={disabled}
    />
  </div >
}

interface IFormWriterPersons extends ComponentProps<'div'> {
  name: string
  label?: string
  disabled?: boolean
}

export const FormWriterPersons: FC<IFormWriterPersons> = ({ name, label, disabled, className }) => {
  // Variables
  const persons = usePersons()
  const options = useMemo(() => persons.data != null
    ? persons.data
      .filter(person => ['ADMIN', 'WRITER'].includes(person.account?.accessType.id))
      .map((person: CoreTypes.IPerson) => ({
        label: formatPersonName(person),
        value: person.account.subjectId
      }))
    : [], [persons.data])

  return <div className={className}>
    <Field
      id={name}
      name={name}
      component={Autocomplete}
      options={options}
      label={label}
      color='secondary'
      className='flex-1'
      disabled={disabled}
    />
  </div >
}

interface IFormImage extends ComponentProps<'div'> {
  name: string
  disabled?: boolean
  images: string[]
}

export const FormImage: FC<IFormImage> = ({ name, disabled, images }) => {
  return <Field
    id={name}
    name={name}
    component={FileUpload}
    disabled={disabled}
    imgClassName='h-70 w-70'
    label={<div className='flex items-center'>
      <FaIcon name='camera' />
    </div>}
    color='secondary'
    defaultFiles={images}
    defaultImage={`${process.env.PUBLIC_URL}/data/images/noPhoto.png`}
  />
}

interface IFormTerritory extends ComponentProps<'div'> {
  name: string
  disabled?: boolean
}

export const FormTerritory: FC<IFormTerritory> = ({ name, disabled, className = COMMON_CLASSNAME }) => {
  // Variables
  const { t } = useTranslation(['common']);
  const territories = useTerritories()
  const options = useMemo(() => territories.data != null ? territories.data.map((territory: CoreTypes.ITerritory) => ({
    label: territory.label,
    value: territory.id
  })) : [], [territories.data])

  return <div className={className}>
    <Field
      id={name}
      name={name}
      component={Autocomplete}
      options={options}
      label={t('common:forms.fields.territory')}
      color='secondary'
      className='flex-1'
      disabled={disabled}
    />
  </div >
}

interface IFormGeo extends ComponentProps<'div'> {
  name: string
  disabled?: boolean
}

export const FormGeo: FC<IFormGeo> = ({ name, disabled, className = COMMON_CLASSNAME }) => {
  // Variables
  const { t } = useTranslation(['common']);

  return <div className={className}>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Field
          id={`${name}-0`}
          name={`${name}[0]`}
          component={TextField}
          label={t('common:forms.fields.longitude')}
          color='secondary'
          className='flex-1'
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          id={`${name}-1`}
          name={`${name}[1]`}
          component={TextField}
          label={t('common:forms.fields.latitude')}
          color='secondary'
          className='flex-1'
          disabled={disabled}
        />
      </Grid>
    </Grid>
  </div >
}

const FormAddressField = ({ field, label }: { field: { name: string, value: string }, label: string }) => <TextField
  field={field}
  form={{}}
  label={label}
  color='secondary'
  className='flex-1'
  disabled
/>

interface IFormAddress extends ComponentProps<'div'> {
  name: string
  disabled?: boolean
  selectedAddressId: string
}

export const FormAddress: FC<IFormAddress> = ({ name, disabled, selectedAddressId, className = COMMON_CLASSNAME }) => {
  // Variables
  const { t } = useTranslation(['common']);
  const addresses = useAddresses()
  const options = useMemo(() => addresses.data != null ? addresses.data.map((address: CoreTypes.IAddress) => ({
    label: address.name,
    value: address.id
  })) : [], [addresses.data])
  const selectedAddress = useMemo(
    () => addresses.data != null
      ? addresses.data.find((address: CoreTypes.IAddress) => address.id === selectedAddressId)
      : undefined
    , [addresses.data, selectedAddressId])

  return <>
    <div className={className}>
      <Field
        id={name}
        name={name}
        component={Autocomplete}
        options={options}
        label={t('common:forms.fields.chooseAddress')}
        color='secondary'
        className='flex-1'
        disabled={disabled}
      />
    </div>
    <div className={className}>
      <FormAddressField field={{ name: 'addressName', value: selectedAddress?.name ?? '' }} label={t('common:forms.fields.name')} />
    </div>
    <div className={className}>
      <FormAddressField field={{ name: 'line1', value: selectedAddress?.line1 ?? '' }} label={t('common:forms.fields.street')} />
    </div>
    <div className={className}>
      <FormAddressField field={{ name: 'line2', value: selectedAddress?.line2 ?? '' }} label={t('common:forms.fields.complement')} />
    </div>
    <div className={className}>
      <FormAddressField field={{ name: 'city', value: selectedAddress?.city ?? '' }} label={t('common:forms.fields.city')} />
    </div>
    <div className={className}>
      <FormAddressField field={{ name: 'zip', value: selectedAddress?.zip ?? '' }} label={t('common:forms.fields.postcode')} />
    </div>
    <div className={className}>
      <FormAddressField field={{ name: 'country', value: selectedAddress?.country ?? '' }} label={t('common:forms.fields.country')} />
    </div>
  </>
}

interface IFormAccessType extends ComponentProps<'div'> {
  name: string
  disabled?: boolean
}

export const FormAccessType: FC<IFormAccessType> = ({ name, disabled, className = COMMON_CLASSNAME }) => {
  // Variables
  const { t } = useTranslation(['common']);
  const accessTypes = useAccessTypes()
  const options = useMemo(() => accessTypes.data != null ? accessTypes.data.map(accessType => ({
    label: accessType.label,
    value: accessType.id
  })) : [], [accessTypes.data])

  return <div className={className}>
    <Field
      id={name}
      name={name}
      component={Autocomplete}
      options={options}
      label={t('common:forms.fields.accessType')}
      color='secondary'
      className='flex-1'
      disabled={disabled}
    />
  </div >
}

interface IFormComment extends ComponentProps<'div'> {
  name: string
  disabled?: boolean
}

export const FormComment: FC<IFormComment> = ({ name, disabled, className = COMMON_CLASSNAME }) => {
  // Variables
  const { t } = useTranslation(['common']);

  return <div className={className}>
    <Field
      id={name}
      name={name}
      component={TextField}
      label={t('common:forms.fields.comment')}
      color='secondary'
      className='flex-1'
      multiline
      disabled={disabled}
    />
  </div >
}

interface IFormKeywords extends ComponentProps<'div'> {
  name: string
  disabled?: boolean
}

export const FormKeywords: FC<IFormKeywords> = ({ name, disabled, className = COMMON_CLASSNAME }) => {
  // Variables
  const { t } = useTranslation(['common']);

  return <div className={className}>
    <Field
      id={name}
      name={name}
      component={TextField}
      label={t('common:forms.fields.keywords')}
      color='secondary'
      className='flex-1'
      disabled={disabled}
    />
  </div >
}

interface IFormSubmitButtons extends ComponentProps<'div'> {
  disabled?: boolean
  onCancel: () => void
  isSubmitting: boolean
  onSubmit?: (...args: any) => void
  size?: any
}

export const FormSubmitButtons: FC<IFormSubmitButtons> = ({ size = 'large', onSubmit, isSubmitting, disabled, onCancel }) => {
  // Variables
  const { t } = useTranslation(['common']);

  return <div className='flex w-full items-center justify-center'>
    <Button
      type='reset'
      variant='text'
      size={size}
      className='mx-2'
      onClick={onCancel}
    >
      {t('common:forms.fields.cancel')}
    </Button>
    <Button
      type={onSubmit === undefined ? 'submit' : undefined}
      variant='text'
      size={size}
      className='mx-2'
      disabled={disabled}
      onClick={onSubmit}
      startIcon={
        isSubmitting ? <FaIcon name='spinner' className='fa-spin' /> : null
      }
    >
      {t('common:forms.fields.save')}
    </Button>
  </div >
}

interface IFormPreviewFieldValue {
  label: string
  value: any
  color?: string
}

export const FormPreviewFieldValue: FC<IFormPreviewFieldValue> = ({ label, value, color }) => {
  // Variables
  const { t } = useTranslation(['common']);
  const commonStyle = {
    fontSize: 14
  }
  const displayedValue = useMemo(() => {
    if (value === '' || value == null || (Array.isArray(value) && value.length === 0)) {
      return <i>{t('common:forms.fields.undefined')}</i>
    }

    if (Array.isArray(value)) {
      return value.join(', ')
    }

    if (typeof value === 'string') {
      return getStringPreview(value)
    }

    return value
  }, [t, value])

  return <>
    <p className='my-1'>
      <strong className='mb-1' style={commonStyle}>{label}</strong><br />
      <span style={{
        color,
        ...commonStyle
      }}>{displayedValue}</span>
    </p>
  </>
}

export const FormPoiCustomValuesPreview = ({ poi }: { poi: CoreTypes.IPoi }) => {
  // Variables
  const { t } = useTranslation(['common']);
  const valueDefsByType = useMemo(() => mapValues(groupBy(poi.type.valueDefs, 'title'), valueDefs => sortBy(valueDefs, ['order', 'valueDef.label'])), [poi.type.valueDefs])
  const valueDefsValues = useMemo(() => {
    const valuesByValueDefs: { [id: string]: string } = {}
    poi.valueSlots.forEach(valueSlot => {
      valuesByValueDefs[valueSlot.valueDef.id] = valueSlot.value
    })
    return valuesByValueDefs
  }, [poi.valueSlots])

  // Handlers
  const getValueDisplay = useCallback((valueDef: any) => {
    const value = valueDefsValues[valueDef.id]
    if (value === undefined) {
      return ''
    }

    switch (valueDef.widget.id) {
      case 'LIST': case 'RADIOS': return valueDef.valueChoices.find((valueChoice: any) => valueChoice.value === value)?.label
      case 'BOOLEAN': return value === 'true' ? t('common:forms.fields.yes') : t('common:forms.fields.no')
      case 'VALUESLIST': case 'CHECKBOXES':
        const values = value.split(',')
        return valueDef.valueChoices.filter((valueChoice: any) => values.includes(valueChoice.value)).map(({ label }: any) => label).join(', ')
      case 'DATE': return moment(value).format('DD/MM/YYYY')
      case 'THREECOLORSSCALES': return <div>
        {Object.values(ColorRadioByValue).map((Component, key) => <Component key={key} selectedValue={value} />)}
      </div>
      case 'FIELD': return <div>{getStringPreview(value)}</div>
      default: return value
    }
  }, [t, valueDefsValues])

  return <>
    {Object.keys(valueDefsByType).sort().map((title: string, key: number) => <Grid item xs={12} key={key}>
      <FormTitle title={title} />
      <div>
        {valueDefsByType[title].map((valueDef: any, key: number) => <FormPreviewFieldValue label={valueDef.valueDef.label} value={getValueDisplay(valueDef.valueDef)} key={key} />)}
      </div>
    </Grid>)}
  </>
}

interface IValueDefField extends ComponentProps<'div'> {
  valueDef: CoreTypes.IValueDef
}

export const ValueDefField: FC<IValueDefField> = ({ valueDef, className = COMMON_CLASSNAME }) => {
  // Variables
  const { t } = useTranslation(['common']);
  const widgetId = valueDef.widget.id
  const options = useMemo(() => widgetId === 'BOOLEAN'
    ? [{
      label: t('common:forms.fields.yes'),
      value: 'true'
    },
    {
      label: t('common:forms.fields.no'),
      value: 'false'
    }]
    : valueDef.valueChoices.map(({ label, value }: CoreTypes.IValueChoice) => ({
      label,
      value
    })), [t, valueDef.valueChoices, widgetId])

  switch (widgetId) {
    case 'LIST': case 'VALUESLIST': case 'BOOLEAN': return <div className={className}>
      <Field
        name={valueDef.id}
        label={valueDef.label}
        component={Autocomplete}
        className='flex-1'
        options={options}
        multiple={widgetId === 'VALUESLIST'}
        color='secondary'
      />
    </div>
    case 'NUMBER': case 'FLOAT': case 'FIELD':
      const otherProps = widgetId !== 'FIELD'
        ? {
          type: 'number',
          transform: widgetId === 'NUMBER' ? 'integer' : 'float',
          nativeInputProps: {
            step: widgetId === 'NUMBER' ? '1' : '0.01'
          }
        }
        : {
          multiline: true
        }

      return <div className={className}>
        <Field
          name={valueDef.id}
          label={valueDef.label}
          className='flex-1'
          component={TextField}
          color='secondary'
          {...otherProps}
        />
      </div>
    case 'RADIOS': return <div className={className}>
      <Field
        name={valueDef.id}
        clearable
        label={valueDef.label}
        row
        className='flex-1'
        component={RadioGroup}
        options={options}
        color='secondary'
      />
    </div>
    case 'CHECKBOXES': return <div className={className}>
      <Field
        name={valueDef.id}
        label={valueDef.label}
        className='flex-1'
        component={CheckboxGroup}
        row
        options={options}
        color='primary'
      />
    </div>
    case 'DATE': return <div className={className}>
      <Field
        name={valueDef.id}
        label={valueDef.label}
        className='flex-1'
        component={DatePicker}
        transform={(date: any) => (date !== '' && date != null) ? momentToISOString(date) : ''}
        format='DD/MM/YYYY'
        color='secondary'
      />
    </div>
    case 'THREECOLORSSCALES': return <div className={className}>
      <Field
        name={valueDef.id}
        label={valueDef.label}
        className='flex-1'
        component={ThreeColorsRadio}
        clearable
      />
    </div>
    default: return null
  }
}

export const getStringPreview = (s: string) => s.split('\n').map((line, key) => {
  const words = line.split(' ')
  const protocols = ['http://', 'https://']

  const elements: any[] = words.map((word, key) =>
    <Fragment key={key}>
      {urlRegexSafe().test(word)
        ? <a
          href={
            protocols.some(protocol => word.startsWith(protocol))
              ? word
              : `http://${word}`
          }
          target='_blank'
          rel='noopener noreferrer'
          style={{
            textDecoration: 'underline',
            color: 'blue'
          }}
        >
          {word}
        </a>
        : <span>{word}</span>
      }
      {words.length > 1 && <>&nbsp;</>}
    </Fragment>
  )

  return <Fragment key={key}>{elements}<br /></Fragment>
})

interface IFieldWrapper {
  children: any
  align?: 'start' | 'center' | 'end'
  label?: string | JSX.Element
  disabled?: boolean
  bgColor?: string
  hideOnKeyDown?: boolean
}

export const FieldWrapper = ({
  children,
  label = '',
  align = 'center',
  disabled = false,
  bgColor = 'main-light',
  hideOnKeyDown = false
}: IFieldWrapper) => {
  // State
  const [isFieldDisplayed, setIsFieldDisplayed] = useState<boolean>(false)
  // Actions
  const displayField = useCallback(() => setIsFieldDisplayed(true), [])
  const hideField = useCallback(() => setIsFieldDisplayed(false), [])
  const onKeyDown = useCallback((e: any) => {
    if (hideOnKeyDown && e.key === 'Enter') {
      hideField()
    }
  }, [hideField, hideOnKeyDown])
  // Variables
  const displayedLabel = useMemo(() => typeof label === 'string' ? getStringPreview(label) : label, [label])

  return isFieldDisplayed
    ? <ClickAwayListener mouseEvent="onMouseDown" onClickAway={hideField}><div onKeyDown={onKeyDown}>{children}</div></ClickAwayListener>
    : <div className={`w-full bg-${bgColor} text-select flex items-${align} justify-${align} text-xs`}>
      {disabled
        ? <label>{displayedLabel}</label>
        : <label className='cursor-pointer flex items-center' onClick={displayField}><span>{displayedLabel}</span> <FaIcon name='pencil' className='ml-2' /></label>
      }
    </div>
}
